var sz_logo = $('#sz_logo')
var logo_cloud = $('#logo_cloud');
// CHARACTERS
var kreamer = $('#kreamer');
var kreamer_wake = $('#kreamer_wake');
var sir_prize = $('#sir_prize');
var sir_prize_wake = $('#sir_prize_wake');
var juicetice = $('#juicetice');
var juicetice_wake = $('#juicetice_wake');
var dr_tropic = $('#dr_tropic');
var dr_tropic_wake = $('#dr_tropic_wake');
var captain_k = $('#captain_k');
var captain_k_wake = $('#captain_k_wake');
var darkblues = $('#darkblues');
var darkblues_wake = $('#darkblues_wake');
var powerbucket = $('#powerbucket');
var powerbucket_wake = $('#powerbucket_wake');
var sandstorm = $('#sandstorm');
var sandstorm_wake = $('#sandstorm_wake');



$(function(){

    $('.onload').fadeOut(1000, function(){
        animate_characters();
    });

    //  Animate characters
    function animate_characters(){
        sz_logo.addClass('animate');
        // Animate after logo
        sz_logo.one(animationEvent, function(e) {
            // Cloud
            animate_cloud();
            // KREAMER
            animate_kreamer();
            // SIR PRIZE
            animate_sir_prize();
            // JUICETICE
            setTimeout(function(){
                animate_juicetice();
            }, 300);
            // DR TROPIC
            setTimeout(function(){
                animate_dr_tropic();
            }, 400);
            // CAPTAIN K
            setTimeout(function(){
                animate_captain_k();
            }, 500);
            // DARKBLUES
            setTimeout(function(){
                animate_darkblues();
            }, 600);
            // POWERBUCKET
            setTimeout(function(){
                animate_powerbucket();
            }, 700);
            // SANDSTORM
            setTimeout(function(){
                animate_sandstorm();
            }, 800);

        });
    }

    // ANIMATIONS
    // CLOUD
    function animate_cloud(){
        logo_cloud.addClass('animate');
    }
    // KREAMER
    function animate_kreamer(){
        kreamer.addClass('animate');
        kreamer_wake.addClass('animate');
    }
    // SIR PRIZE
    function animate_sir_prize(){
        sir_prize.addClass('animate');
        sir_prize_wake.addClass('animate');
    }
    // JUICETICE
    function animate_juicetice(){
        juicetice.addClass('animate');
        juicetice_wake.addClass('animate');
    }
    // DR TROPIC
    function animate_dr_tropic(){
        dr_tropic.addClass('animate');
        dr_tropic_wake.addClass('animate');
    }
    // CAPTAIN K
    function animate_captain_k(){
        captain_k.addClass('animate');
        captain_k_wake.addClass('animate');
    }
    // DARKBLUES
    function animate_darkblues(){
        darkblues.addClass('animate');
        darkblues_wake.addClass('animate');
    }
    // POWERBUCKET
    function animate_powerbucket(){
        powerbucket.addClass('animate');
        powerbucket_wake.addClass('animate');
    }
    // SANDSTORM
    function animate_sandstorm(){
        sandstorm.addClass('animate');
        sandstorm_wake.addClass('animate');
    }
});

// Detect animation end in all browsers
function whichAnimationEvent(){
    var t,
        el = document.createElement("fakeelement");

    var animations = {
        "animation"      : "animationend",
        "OAnimation"     : "oAnimationEnd",
        "MozAnimation"   : "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
    }

    for (t in animations){
        if (el.style[t] !== undefined){
            return animations[t];
        }
    }
}

var animationEvent = whichAnimationEvent();