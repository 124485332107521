
var hero_car = $('#hero_car'), villain_car = $('#villain_car');

// Show download info
function download_item_animate(){
    $('.download_item_wrapper').on('mouseenter', function(){
        // console.log('in');
        $(this).find('.download_item_info').show();
    }).on('mouseleave', function(){
        $(this).find('.download_item_info').hide();
    })

}

$(function(){

    download_item_animate();

    // Animate characters when section is in view
    function animate_hero_car(){
        hero_car.addClass('animate');
    }
    function animate_villain_car(){
        hero_car.addClass('animate');
    }

    var items = document.querySelectorAll('.downloads');

    // Check if element is in viewport
    function isElementInViewport(el){
        var rect = el.getBoundingClientRect();
        return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= $(window).height() &&
        rect.right <= $(window).width()

        );
    }

    // Show elements
    function show_characters(){
        for(var i = 0; i < items.length; i++) {
            if (isElementInViewport(items[i])) {
                items[i].classList.add('on');
                // Animate characters
                hero_car.addClass('animate');
                villain_car.addClass('animate');
            }
        }
    }

    // listen for events
    window.addEventListener("load", show_characters);
    window.addEventListener("resize", show_characters);
    window.addEventListener("scroll", show_characters);
})