
$(function(){

	var imgPopup = $('.img-mainpopup');
	var popupImage = $('.img-mainpopup .img-popup-container img');
	var closeBtn = $('.close-btn');

	setTimeout(function(){
		imgPopup.addClass('opened');
	},2500);

	$(imgPopup, closeBtn).on('click', function() {
		imgPopup.removeClass('opened');
	});

	popupImage.on('click', function(e) {
		e.stopPropagation();
	});
	$('.onload').fadeOut(1000, function(){
		
	});

});