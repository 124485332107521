

var donut = $('#donut'), donut_wake = $('#donut_wake'), lock = $('#lock'), lock_wake = $('#lock_wake'), crossant = $('#crossant'), crossant_wake = $('#crossant_wake'), key = $('#key'), key_wake = $('#key_wake');



var products_value, product_id;

var products_series_value;


var product_detail_img;

var $products_series = ['series_one', 'toy_range', 'series_two', 'series_three', 'toy_range_s3'];

var $products_series_one = ['one_pack', 'hideout', 'supercar', 'blister4', 'blister10', 'starter_pack'];

var $products_series_two = ['one_pack', 'hideout', 'supercar', 'blister4', 'blister10', 'starter_pack'];

var $products_toy_range = ['kaboom_trap', 'mission_1', 'cyber_squad'];

var product_series_index, product_range_index, total_products_images;

// First image of product detail
var product_range_image_index = 0;

var menu_item;

var $product_images = [
    // Series One
    [
        // One Pack
        [
            // A
            '../img/products/series_one/detail/one_pack/one_pack.png',
            // B
            '../img/products/series_one/detail/one_pack/display.png'
        ],
        // Hideout
        [
            // A
            '../img/products/series_one/detail/hideout/hideout.png',
            // B
            '../img/products/series_one/detail/hideout/display.png'
        ],
        // Supercar
        [
            // A
            '../img/products/series_one/detail/supercar/supercar_v1.png',
            // B
            '../img/products/series_one/detail/supercar/supercar_h1.png',
            // C
            '../img/products/series_one/detail/supercar/supercar_v2.png',
            // D
            '../img/products/series_one/detail/supercar/supercar_h2.png',
            // E
            '../img/products/series_one/detail/supercar/display.png'
        ],
        // Blister 4
        [
            //A
            '../img/products/series_one/detail/blister4/blister4.png',
        ],
        // Blister 10
        [
            //A
            '../img/products/series_one/detail/blister10/blister10.png',
        ],
        // Starter Pack
        [
            //A
            '../img/products/series_one/detail/starter_pack/starter_pack_products.png'
        ]
    ],
    // Toy Range
    [
        // Kaboom Trap
        [
            // A
            '../img/products/toy_range/detail/kaboom_trap/kaboom_trap.png',
            // B
            '../img/products/toy_range/detail/kaboom_trap/display.png'
        ],
        // Mission 1
        [
            // A
            '../img/products/toy_range/detail/mission_1/mission_1.png',
            // B
            '../img/products/toy_range/detail/mission_1/mission_1_blister.png'
        ]
        ,
        // CYBER SQUAD
        [
            // A
            '../img/products/toy_range/detail/cyber_squad/cyber_squad.png',
            // B
            '../img/products/toy_range/detail/cyber_squad/cyber_squad_cdu.png'
        ]
        ,
        // ADVENTURE 1
        [
            // A
            '../img/products/toy_range/detail/adventure_1/adventure_1.png',
            // B
            '../img/products/toy_range/detail/adventure_1/adventure_1_box.png'
        ]
        ,
        // ADVENTURE 2
        [
            // A
            '../img/products/toy_range/detail/adventure_2/adventure_2.png',
            // B
            '../img/products/toy_range/detail/adventure_2/adventure_2_box.png'
        ]
        ,
        // POLICE STATION
        [
            // A
            '../img/products/toy_range/detail/police_station/police_station.png',
            // B
            '../img/products/toy_range/detail/police_station/police_station_box.png'
        ],
        // KABOOM BLASTER
        [
            // A
            '../img/products/toy_range/detail/kaboom_blaster/kaboom_blaster.png',
            // B
            '../img/products/toy_range/detail/kaboom_blaster/kaboom_blaster_cdu.png'
        ],
        // MISSION 2
        [
            // A
            '../img/products/toy_range/detail/m2/m2_action.png',
            // B
            '../img/products/toy_range/detail/m2/m2.png'
        ],
        // MISSION 3
        [
            // A
            '../img/products/toy_range/detail/m3/m3_action.png',
            // B
            '../img/products/toy_range/detail/m3/m3.png'
        ]
    ],
    // Series 2
    [
        //One Pack
        [
            // A
            '../img/products/series_two/detail/one_pack/one_pack.png',
            // B
            '../img/products/series_two/detail/one_pack/display.png'
        ],
        // Hideout
        [
            // A
            '../img/products/series_two/detail/hideout/hideout.png',
            // B
            '../img/products/series_two/detail/hideout/display.png'
        ],
        // SuperJet
        [
            // A
            '../img/products/series_two/detail/supercar/supercar_v1.png',
            // B
            '../img/products/series_two/detail/supercar/supercar_h1.png',
            // C
            '../img/products/series_two/detail/supercar/supercar_v2.png',
            // D
            '../img/products/series_two/detail/supercar/supercar_h2.png',
            // E
            '../img/products/series_two/detail/supercar/display.png'
        ],
        // Blister 4
        [
            //A
            '../img/products/series_two/detail/blister4/blister4.png',
        ],
        // Blister 10
        [
            //A
            '../img/products/series_two/detail/blister10/blister10.png',
        ],
        // Starter Pack
        [
            //A
            '../img/products/series_two/detail/starter_pack/starter_pack_products.png'
        ]
    ],
    // Series Three
    [
        //  One Pack
        [
            // A
            '../img/products/series_three/detail/one_pack/one_pack.png',
            // B
            '../img/products/series_three/detail/one_pack/one_pack_cdu.png'
        ],
        //  Superslider
        [
            // A
            '../img/products/series_three/detail/superslider/superslider.png',
            // B
            '../img/products/series_three/detail/superslider/superslider_cdu.png'
        ],
        // Superbots
        [
            // A
            '../img/products/series_three/detail/superbots/h1.png',
            // B
            '../img/products/series_three/detail/superbots/h2.png',
            // C
            '../img/products/series_three/detail/superbots/v1.png',
            // D
            '../img/products/series_three/detail/superbots/v2.png',
            // E
            '../img/products/series_three/detail/superbots/cdu.png'
        ],
        // Blister 5
        [
            // Blister
            '../img/products/series_three/detail/blister5/blister5.png'
        ],
        // Blister 10
        [
            // Blister
            '../img/products/series_three/detail/blister10/blister10.png'
        ],
        // SP
        [
            // Blister
            '../img/products/series_three/detail/starter_pack/starter_pack.png'
        ]

    ]
]

$(function(){


    // Donut
    function animate_donut(){
        donut.addClass('animate');
    }
    function animate_donut_wake(){
        donut_wake.addClass('animate');
    }
    // LOCK
    function animate_lock(){
        lock.addClass('animate');
    }
    function animate_lock_wake(){
        lock_wake.addClass('animate');
    }
    // Crossant
    function animate_crossant(){
        crossant.addClass('animate');
    }
    function animate_crossant_wake(){
        crossant_wake.addClass('animate');
    }
    // Key
    function animate_key(){
        key.addClass('animate');
    }
    function animate_key_wake(){
        key_wake.addClass('animate');
    }

    // Get items
    var items = document.querySelectorAll('.products');

    // Check if element is in viewport
    function isElementInViewport(el){
        var rect = el.getBoundingClientRect();
        return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= $(window).height() &&
        rect.right <= $(window).width()

        );
    }

    // Show elements
    function show_characters(){
        for(var i = 0; i < items.length; i++) {
            if (isElementInViewport(items[i])) {
                items[i].classList.add('on');
                // Animate characters
                animate_donut(); animate_donut_wake();
                animate_crossant(); animate_crossant_wake();
                animate_lock(); animate_lock_wake();
                animate_key(); animate_key_wake();
            }
        }
    }

    // listen for events
    window.addEventListener("load", show_characters);
    window.addEventListener("resize", show_characters);
    window.addEventListener("scroll", show_characters);
})
