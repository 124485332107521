
// var videos_es_src_array = ['LIXXTJCmpok', 'vf_zeXgNs0k', '_dypN3r870g', 'y5AGKxe4H-I', 'D2jTpgeSjZs', 'wVt2CnLv210',
// 							'-RWXk2KQbEY', 'S-dP5ZGCYLQ', 'tqKfgIYOShk', 'O0u-ugB04Rs', 'lxvElgwP7-g', 'TfRFOEU4i30',
// 							'uyLJaVSGXIU', 'x9sh5mLLDmQ', 'KXqMI4ifo4g'];


$(function(){


    var owl = $('#videos_owl');

    owl.owlCarousel({
        stagePadding: 200,
        loop:false,
        margin: 0,
        nav:false,
        items:1,
        lazyLoad: false,
        responsiveRefreshRate: 20,
        nav:false,
        video: true,
        responsive:{
            0:{
                items:1,
                margin: 15,
                stagePadding: 0
            },
            540:{
                items:1,
                // margin: 15,
                stagePadding: 0
            },
            720:{
                items:1,
                // margin: 15,
                stagePadding: 60
            },
            960:{
                items:1,
                stagePadding: 170 //
            },
            1080:{
                items:1,
                stagePadding: 240 //
            },
            1200:{
                items:1,
                stagePadding: 280
            },
            1400:{
                items:1,
                stagePadding: 340
            },
            1600:{
                items:1,
                stagePadding: 380
            },
            1800:{
                items:1,
                // margin: -50,
                stagePadding: 400
            },
            1900:{
                items:1,
                // margin: -50,
                stagePadding: 450
            },
            2100:{
                items:1,
                // margin: -50,
                stagePadding: 500
            },
            2200:{
                items:1,
                // margin: -50,
                stagePadding: 550
            }
        }
    })

    $('.arrow_container.right').on('click', function(){
        showVideoCover()
        // stop_all_players()
        stopVideos();
        owl.trigger('next.owl.carousel');

    })
    // Scroll left
    $('.arrow_container.left').on('click', function(){
        showVideoCover()
        // stop_all_players()
        stopVideos();
        owl.trigger('prev.owl.carousel');

    })



    function stopVideos(){
        $('.owl-item.active .player').each(function(){
            var el_src = $(this).attr("src");
            $(this).attr("src",el_src);
        });
    }

    // Hide Video cover on click
    // $('body').on('click', '.owl-item.active .video_cover_container', function(){
    // 	$(this).fadeOut(0);
    // });

    // Video covers on
    function showVideoCover(){
        $('.video_cover_container').show();
    }

    // Start hiding left arrow
    $('.arrow_container.left').hide();
    var current_page, number_of_pages;
    // Update arrows on owl carousel on change
    owl.on('changed.owl.carousel',function(e){

        current_page = e.page.index;
        //console.log('current_page: ' + current_page)
        number_of_pages = e.page.count;

        // Last page
        if ((current_page+1) == number_of_pages) {
            $('.arrow_container.right').hide();
        } else {
            $('.arrow_container.right').show();
        }

        //  First page
        if ( current_page == 0  ) {
            $('.arrow_container.left').hide();
        } else {
            $('.arrow_container.left').show();
        }

    });


    var video_data_index;
    // Click on play icon to load source
    $('body').on('click', '.owl-item.active .carousel_item', function(e){

        e.preventDefault();
        // Get data index to get video
        video_data_index = $(this).data('index');

        //$('.player').attr('src', 'https://www.youtube.com/embed/PjWA-LUctVo');
        var cookies = getCookie('cookieControl');
        if(cookies != undefined){
            if(cookies.indexOf('advertising') == -1){
                window.open($(this).data('embed'), '_blank');
            }else{
                $(this).find('.player').attr('src',$(this).data('embed'));
                $(this).find('.video_cover_container').fadeOut(0);
            }
        }else{
            window.open($(this).data('embed'), '_blank');
        }
        
    });




});