
var hero_car2 = $('#hero_car2'), villain_car2 = $('#villain_car2');


$(function(){

    // Animate characters when section is in view
    function animate_hero_car(){
        hero_car2.addClass('animate');
    }
    function animate_villain_car(){
        hero_car2.addClass('animate');
    }

    var items2 = document.querySelectorAll('.instagram');

    // Check if element is in viewport
    function isElementInViewport2(el){
        var rect2 = el.getBoundingClientRect();
        return (
        rect2.top >= 0 &&
        rect2.left >= 0 &&
        rect2.bottom <= $(window).height() &&
        rect2.right <= $(window).width()

        );
    }

    // Show elements
    function show_characters2(){
        for(var i = 0; i < items2.length; i++) {
            if (isElementInViewport2(items2[i])) {
                items2[i].classList.add('on');
                // Animate characters
                hero_car2.addClass('animate');
                villain_car2.addClass('animate');
            }
        }
    }

    // listen for events
    window.addEventListener("load", show_characters2);
    window.addEventListener("resize", show_characters2);
    window.addEventListener("scroll", show_characters2);
})